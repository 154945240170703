<template>
    <div class="loading-con">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>

.loading-con {
    position: fixed;
    top:50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%,-50%);
}

.loading-con>div {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -10px;
    width: 50%;
    height: 20px;
    transform-origin: left center;
}

.loading-con>div::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    /* background-color: #000; */
    transform: var(--scale);
    animation: dotscale 1.3s linear infinite;
    animation-delay: var(--animation-delay);
}

.loading-con>div:nth-child(1) {
    transform: rotate(0deg);
    --animation-delay: 0s;
}

.loading-con>div:nth-child(2) {
    transform: rotate(30deg);
    --animation-delay: -0.1s;
}

.loading-con>div:nth-child(3) {
    transform: rotate(60deg);
    --animation-delay: -0.2s;
}

.loading-con>div:nth-child(4) {
    transform: rotate(90deg);
    --animation-delay: -0.3s;
}

.loading-con>div:nth-child(5) {
    transform: rotate(120deg);
    --animation-delay: -0.4s;
}

.loading-con>div:nth-child(6) {
    transform: rotate(150deg);
    --animation-delay: -0.5s;
}

.loading-con>div:nth-child(7) {
    transform: rotate(180deg);
    --animation-delay: -0.6s;
}

.loading-con>div:nth-child(8) {
    transform: rotate(210deg);
    --animation-delay: -0.7s;
}

.loading-con>div:nth-child(9) {
    transform: rotate(240deg);
    --animation-delay: -0.8s;
}

.loading-con>div:nth-child(10) {
    transform: rotate(270deg);
    --animation-delay: -0.9s;
}

.loading-con>div:nth-child(11) {
    transform: rotate(300deg);
    --animation-delay: -1s;
}

.loading-con>div:nth-child(12) {
    transform: rotate(330deg);
    --animation-delay: -1.1s;
}

img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
}
@keyframes dotscale {
  0% {
    transform: scale(1);
    filter: hue-rotate(0deg);
  }

  100% {
    transform: scale(0);
    filter: hue-rotate(360deg);
  }
}
</style>