<template>
  <div class="node" :class="{ hideCon: showInfo }">
    <h5Top />
    <div class="node-title">
      <el-carousel height="360px" :indicator-position="bannerList.length > 1 ? 'inside' : 'none'">
        <el-carousel-item v-for="item in bannerList" :key="item">
          <img :src="item.imgUrl" alt="" class="bannerimg" @click="handlerBanner(item)" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="node-con-out">
      <div class="node-con">
        <!-- <div class="market-reviset flex" v-if="configInfo2.act == 1">
          <div class="reviset-notice flex">
            <img src="../../public/images/reward/noticeicon.png" alt="" />
            <div>{{ $t('reward.desc167') }} <span>{{ $t('reward.desc168', { n1: '200 - 500' }) }}</span></div>

          </div>
          <div class="reviset-btn flex">

            <div class="btninfo flex flexcenter" @click="$router.push('/reward/pledge?act=1')">
              <img src="../../public/images/reward/icon15.png" alt="" />
              <div>{{ $t('reward.desc170') }}</div>
            </div>
            <div class="btninfo flex flexcenter" @click="showNotice = true">
              <img src="../../public/images/reward/icon7.png" alt="" />
              <div>{{ $t('reward.desc166') }}</div>
            </div>
          </div>
        </div> -->
        <div class="activity-wrap flex" v-if="configInfo2.act == 1 || planetInfo.act == 1">
          <div class="wrap-item flex" v-if="planetInfo.act == 1">
            <img src="../../public/images/reward/planetimg.png" alt="" class="img1" />
            <div class="item-info">
              <div class="info-name flex">
                {{ $t('reward.desc171') }}！ <img src="../../public/images/reward/icon17.png" alt="" />
              </div>
              <div class="info-desc">
                {{ $t('reward.desc209') }}
              </div>
            </div>
            <img src="../../public/images/reward/icon18.png" alt="" class="img2"
              @click="$router.push('/reward/planet')" />
          </div>
          <div class="wrap-item flex" v-if="configInfo2.act == 1">
            <img src="../../public/images/reward/icon3.png" alt="" class="img1" />
            <div class="item-info">
              <div class="info-name flex">
                {{ $t('reward.desc167') }} <img src="../../public/images/reward/icon17.png" alt="" />
              </div>
              <div class="info-desc">
                {{ $t('reward.desc168', { n1: '200 - 500' }) }}
              </div>
            </div>
            <img src="../../public/images/reward/icon18.png" alt="" class="img2"
              @click="$router.push('/reward/pledge?act=1')" />
          </div>
       
        </div>
        <div class="node-type">
          <div class="type-name animate__animated animate__fadeInUp animate__delay-.4s">
            {{ $t("node.desc3") }}
            <p class="flex" @click="$router.push('/manage')">
              {{ $t("node.desc61") }}
              <img src="../../public/images/new/righticon4.png" alt="" />
            </p>
          </div>
          <div class="type-list pctype animate__animated animate__fadeInUp animate__delay-.6s"
            v-if="!ismobile && typeList && typeList.length > 0">
            <img src="../../public/images/new/lefticon4.png" alt="" class="lefticon1" @click="handlerNext(0)"
              v-if="bannersubList.length > 1" />
            <img src="../../public/images/new/righticon7.png" alt="" class="lefticon2" @click="handlerNext(1)"
              v-if="bannersubList.length > 1" />
            <van-swipe class="my-swipe" ref="swiper" :loop="false" indicator-color="white" :autoplay="50000000"
              :show-indicators="false">
              <van-swipe-item v-for="(item, vanI) in bannersubList" :key="vanI">
                <div class="type-list-con flex">
                  <div class="list-info" v-for="itemIn in item" :key="itemIn.id">
                    <div class="list-con flex">
                      <div class="info-img" v-if="itemIn.icon && vanI == pcVanIndex">
                        <star-img :sphereImg="itemIn.icon" :starWidth="ismobile ? 100 : 130"
                          :starHeight="ismobile ? 100 : 130" />
                      </div>
                      <div class="info-desc">
                        <div class="desc-name">{{ itemIn.name }}</div>
                        <div class="desc-price flex">
                          <div class="price">
                            <div>
                              {{ itemIn.price }} {{ itemIn.payCoinName }}
                            </div>
                            <p>{{ $t("node.desc60") }}</p>
                          </div>

                          <div class="price">
                            <div>{{ itemIn.count }}</div>
                            <p>{{ $t("node.desc80") }}</p>
                          </div>
                          <div class="price">
                            <div>
                              <span v-if="itemIn.id == 1">{{ $t("node.desc5") }}*</span>{{ itemIn.landTax }}%
                            </div>
                            <p>
                              {{ $t("node.desc39") }}
                              <el-tooltip :content="$t('node.desc73', { n1: itemIn.landTax })
                                " effect="dark" placement="bottom">
                                <img src="../../public/images/new/tips.png" alt="" />
                              </el-tooltip>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
          <div v-if="ismobile" class="type-list h5type flex animate__animated animate__fadeInUp animate__delay-.6s">
            <van-swipe class="my-swipe" :show-indicators="false" :loop="false" indicator-color="white" :autoplay="5000"
              @change="onChangeSub">
              <van-swipe-item v-for="(itemIn, vanI) in typeList" :key="vanI">
                <div class="list-info">
                  <div class="list-con flex">
                    <div class="info-img" v-if="itemIn.icon && vanI == subact">
                      <star-img :sphereImg="itemIn.icon" :starWidth="ismobile ? 100 : 130"
                        :starHeight="ismobile ? 100 : 130" />
                    </div>
                    <div class="info-desc">
                      <div class="desc-name">{{ itemIn.name }}</div>
                      <div class="desc-price flex">
                        <div class="price">
                          <div>{{ itemIn.price }} {{ itemIn.payCoinName }}</div>
                          <p>{{ $t("node.desc60") }}</p>
                        </div>

                        <div class="price">
                          <div>{{ itemIn.count }}</div>
                          <p>{{ $t("node.desc80") }}</p>
                        </div>
                        <div class="price">
                          <div>
                            <span v-if="itemIn.id == 1">{{ $t("node.desc5") }}*</span>{{ itemIn.landTax }}%
                          </div>
                          <p>
                            {{ $t("node.desc39") }}
                            <el-tooltip :content="$t('node.desc73', { n1: itemIn.landTax })
                              " effect="dark" placement="bottom">
                              <img src="../../public/images/new/tips.png" alt="" />
                            </el-tooltip>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </van-swipe-item>
            </van-swipe>
            <div class="line-all flex">
              <div v-for="(itemIn, index) in typeList" :class="{ subact: subact == index }" :key="itemIn.id"></div>
            </div>
          </div>
          <div class="type-select animate__animated animate__fadeInUp animate__delay-.8s">
            <div class="select">
              <div>{{ $t("node.desc10") }}</div>
              <p>
                <el-select v-model="status" @change="hanlderSelect">
                  <el-option :label="$t('planet.desc40')" :value="''"></el-option>
                  <el-option :label="$t('node.desc35')" :value="2"></el-option>

                  <el-option :label="$t('node.desc34')" :value="1"></el-option>
                  <el-option :label="$t('node.desc33')" :value="0"></el-option>
                </el-select>
              </p>
            </div>
            <div class="select">
              <div>{{ $t("node.desc4") }}</div>
              <p>
                <el-select v-model="priceSort" @change="hanlderSelect">
                  <el-option :label="$t('planet.desc40')" :value="''"></el-option>
                  <el-option :label="$t('node.desc36')" :value="0"></el-option>
                  <el-option :label="$t('node.desc37')" :value="1"></el-option>
                </el-select>
              </p>
            </div>
            <div class="select">
              <div>{{ $t("record.desc4") }}</div>
              <p>
                <el-select v-model="typeId" @change="hanlderSelect">
                  <el-option :label="$t('planet.desc40')" :value="''"></el-option>
                  <el-option :label="item.name" :value="item.id" v-for="item in typeList" :key="item.id"></el-option>
                </el-select>
              </p>
            </div>
            <div class="select">
              <div>{{ $t("node.desc8") }}</div>
              <p class="flex">
                <input type="tel" oninput="value=value.replace(/[^\d]/g,'')" v-model="track"
                  :placeholder="$t('home.desc55')" @change="hanlderSelect" />
                <input type="tel" v-model="no" oninput="value=value.replace(/[^\d]/g,'')" :placeholder="$t('home.desc56')"
                  @change="hanlderSelect" />
              </p>
            </div>
          </div>
          <div class="node-planet flex animate__animated animate__fadeInUp animate__delay-.8s">
            <div class="planet-list" v-for="item in list" :key="item.id">
              <div class="img" v-if="item.icon">
                <star-img :sphereImg="item.icon" :starWidth="ismobile ? 120 : 260" :starHeight="ismobile ? 120 : 260" />
              </div>
              <img src="../../public/images/new/tagicon2.png" alt="" class="icon" />
              <div class="planet-info">
                <div class="planet-name">{{ item.name }}</div>
                <div class="planet-type flex">
                  <div class="price">
                    <div>{{ item.price }} {{ item.payCoinName }}</div>
                    <p>{{ $t("node.desc60") }}</p>
                  </div>
                  <div class="price">
                    <div>{{ item.coord }}</div>
                    <p>{{ $t("node.desc8") }}</p>
                  </div>
                  <div class="price">
                    <div>{{ item.level }}</div>
                    <p>{{ $t("node.desc9") }}</p>
                  </div>
                  <div class="price">
                    <div v-if="item.status == 0">{{ $t("planet.desc67") }}</div>
                    <div v-else-if="item.status == 1">
                      {{ $t("planet.desc68") }}
                    </div>
                    <div v-else>{{ $t("planet.desc69") }}</div>
                    <p>{{ $t("node.desc10") }}</p>
                  </div>
                </div>
                <div class="planet-btn animate__animated animate__fadeInUp" @click="handlerInfo(item)"
                  v-if="item.status == 2">
                  {{ $t("node.desc11") }}
                </div>
                <div class="planet-btn animate__animated animate__fadeInUp" v-else @click="handlerInfo(item)">
                  {{ $t("planet.desc13") }}
                </div>
              </div>
            </div>
          </div>
          <div class="planet-page animate__animated animate__fadeInUp animate__delay-1s">
            <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page"
              :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <nodeWrappar v-if="showInfo" :nodeId="nodeId" :level="level" @handlerClose="handlerClose" @handlerBuy="handlerBuy" />
    <marketInfo :planetId="nodeId" :levelId="level" @handlerClose="handlerClose1" :type="1" v-if="showInfo1" />
    <van-popup v-model:show="showTips">
      <div class="modal-con">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showTips = false" />
        <div class="modal-title">{{ $t("node.desc49") }}</div>
        <div class="modal-desc">{{ $t("node.desc50") }}</div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="showTips = false">
            {{ $t("node.desc51") }}
          </div>
          <div class="flexcenter" @click="handlerSubmit">
            {{ $t("node.desc52") }}
          </div>
        </div>
      </div>
    </van-popup>
    <div id="footer">
      <glFooter :act="99" />
    </div>
    <PageLoading v-model:show="showLoading" />
    <!-- 活动规则 -->
    <van-popup v-model:show="showNotice" :close-on-click-overlay="false">
      <div class="notice-con">
        <img src="../../public/images/new/closeicon2.png" alt="" class="close" @click="showNotice = false" />
        <div class="notice-name">{{ $t("reward.desc17") }}：</div>

        <div class="rules-list">
          <div>{{ $t('reward.desc54') }}</div>
          <div>{{ $t('reward.desc55') }}{{ configInfo2.startTime }} - {{ configInfo2.endTime }}(GST)</div>
          <div>{{ $t('reward.desc56') }}</div>
          <div>{{ $t('reward.desc57') }} <span v-for="item in configInfo2.list">{{ item.levelName }} <label>{{
            item.amount }} {{ item.coinName }}</label>，</span></div>
          <div>{{ $t('reward.desc58') }}</div>
          <div>{{ $t('reward.desc59') }}<span v-for="item in configInfo2.list">{{ item.levelName }} <label>{{
            item.amount }} {{ item.coinName }}</label>，</span></div>
          <div>{{ $t('reward.desc60') }}{{ configInfo2.coinName }}</div>
          <div>{{ $t('reward.desc61') }}{{ configInfo2.awardAccountTypeName }}</div>
          <div>{{ $t('reward.desc62') }}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import glFooter from "@/componets/footer.vue";
import h5Top from "@/componets/h5top.vue";
import nodeWrappar from "../../componets/nodeInfo.vue";
import starImg from "@/componets/starImg.vue";
import marketInfo from "@/componets/marketInfo.vue";
import Loading from "@/componets/loading.vue";
export default {
  components: {
    nodeWrappar,
    glFooter,
    h5Top,
    starImg,
    marketInfo,
    Loading,
  },
  data() {
    return {
      showInfo1: "",
      autoplay: 50000000,
      showTips: false,
      page: 1,
      totals: 0,
      showInfo: false,
      showLeft: true,
      status: "",
      typeId: "",
      priceSort: "",
      track: "",
      no: "",
      typeList: [],
      list: [],
      coord: "",
      nodeId: "",
      level: "",
      bannerList: [],
      ismobile: false,
      bannersubList: [],
      subact: 0,
      pcVanIndex: 0,
      showLoading: true,
      configInfo2: {},
      showNotice: false,
      lang: '',
      planetInfo: {}
    };
  },
  mounted() {
    this.ismobile = localStorage.getItem("ismobile") == 1 ? true : false;
    this.lang = localStorage.getItem('language')
    this.handleInit();
    this.getlist();
    this.$post(this.URL.reward.marketConfig, {}).then(res => {
      if (res.code == 0) {
        let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime();

        if (todayTime > res.data.endTimeStamp + time1) {
          res.data.act = 2;
        } else if (todayTime < res.data.startTimeStamp + time1) {
          res.data.act = 0;

        } else {

          res.data.act = 1;
        }
        res.data.coinName = res.data.list[0].coinName;
        if (res.data.accountType == 1) {
          res.data.awardAccountTypeName = this.$t('new.desc7')
        } else if (res.data.accountType == 2) {
          res.data.awardAccountTypeName = this.$t('new.desc8')
        } else {
          res.data.awardAccountTypeName = this.$t('new.desc9')
        }
        this.configInfo2 = res.data;
      }
    })
    this.$post(this.URL.reward.planetConfig, {
      type: 1
    }).then(res => {
      if (res.code == 0) {
        let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime(), startTime = new Date(res.data.startTime).getTime() + time1, endTime = new Date(res.data.endTime).getTime() + time1;
        if (todayTime > endTime) {
          res.data.act = 2;
        } else if (todayTime < startTime) {
          res.data.act = 0;
          res.data.time = startTime - todayTime;
        } else {
          res.data.time = endTime - todayTime
          res.data.act = 1;
        }
        this.planetInfo = res.data;
      }
    })
  },
  methods: {
    async handleInit() {
      try {
        let res = null;
        if (window.screen.width > 767) {
          res = await this.$post(this.URL.home.banner, {
            positionKey: "PLANET_NODE_BANNER",
          });
        } else {
          res = await this.$post(this.URL.home.banner, {
            positionKey: "PLANET_NODE_BANNER_H5",
          });
        }
        if (res.code == 0) {
          this.bannerList = res.data;
        }

        this.showLoading = false;
      } catch (error) {
        this.showLoading = false;
      }
    },
    handlerBuy() {
      this.showInfo = false;
      setTimeout(() => {
        this.showInfo1 = true;
      }, 500);
    },
    handlerClose1() {
      this.showInfo1 = false;
    },
    onChangeSub(val) {
      this.subact = val;
    },
    handlerNext(i) {
      if (i == 0) {
        this.$refs.swiper.prev();
        if (this.pcVanIndex > 0) this.pcVanIndex--;
      } else {
        this.$refs.swiper.next();
        if (this.pcVanIndex < 2) this.pcVanIndex++;
      }
    },
    handlerBanner(info) {
      if (info.forwardPath != "" && localStorage.getItem('token') == null) {
        this.$router.push('/login')
      } else if (info.forwardPath != "" && localStorage.getItem('token') != null) {
        window.location.href = info.forwardPath;
      }
    },
    handlerSubmit() {
      this.showTips = false;
      this.$router.push("/login");
    },
    hanlderSelect(val) {
      this.page = 1;
      this.list = [];
      this.getlist();
    },
    async getlist() {
      this.typeList = [];
      const typeRes = await this.$post(this.URL.star.type, {});
      if (typeRes.code == 0) {
        this.typeList = typeRes.data;
        for (let i = 0; i < this.typeList.length; i += 3) {
          this.bannersubList.push(this.typeList.slice(i, i + 3));
        }
      }
      const res = await this.$post(this.URL.node.list, {
        status: this.status,
        typeId: this.typeId,
        priceSort: this.priceSort,
        track: this.track,
        no: this.no,
        page: this.page,
        pageSize: 10,
      });
      if (res.code == 0) {
        this.list = res.data.list;
        this.totals = parseFloat(res.data.page.count);
      }
    },
    handlerShow() {
      this.showLeft ? (this.showLeft = false) : (this.showLeft = true);
    },
    handlerInfo(item) {
      if (localStorage.getItem("token") == null) {
        this.showTips = true;
        return;
      }
      this.nodeId = item.id;
      this.level = item.level;
      this.showInfo = true;
    },
    handlerClose() {
      this.showInfo = false;
      this.page = 1;
      this.list = [];
      this.getlist();
    },
    handlerCurrent(val) {
      this.page = val;
      this.list = [];
      this.getlist();
    },
  },
};
</script>

<style lang="less" scoped>
.node {
  position: relative;
  padding: 96px 0 80px;

  .node-search {
    position: relative;
    position: fixed;
    top: 136px;
    left: 0;
    width: 230px;
    z-index: 56;

    .back {
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    .search-info {
      flex: 0 0 200px;
      width: 200px;
      padding: 24px 0 20px;
      background: rgba(0, 0, 0, 0.3);
      border: 1px solid #3a3a3a;
      box-sizing: border-box;
      border-left: 0;

      .search-name {
        margin: 0 0 28px 16px;
        font-size: 16px;
        color: #ffffff;
      }

      .search-title {
        margin: 0 0 12px 16px;
        font-size: 14px;
        color: #fff;
      }

      .search-type {
        width: 168px;
        margin: 0 auto 32px;
        flex-wrap: wrap;

        div {
          flex: 0 0 80px;
          width: 80px;
          height: 34px;
          font-size: 13px;
          color: #ffffff;
          margin: 8px 8px 0 0;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 4px;
          cursor: pointer;

          &:nth-child(2n) {
            margin-right: 0;
          }

          &:nth-child(-n + 2) {
            margin-top: 0;
          }

          &:hover {
            background: #c9fa5b;
            color: #000000;
          }
        }
      }

      .search-input {
        width: 160px;
        height: 34px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-sizing: border-box;
        margin: 0 auto;

        input {
          width: 136px;
          height: 32px;
          margin-left: 12px;
          font-size: 14px;
          color: #fff;

          &::placeholder {
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }
    }
  }

  .minwidth {
    width: 30px;
  }

  .node-title {
    .bannerimg {
      width: 100%;
      height: 360px;
      object-fit: cover;
    }
  }

  .node-con {
    width: 1240px;
    margin: 0 auto;

    .activity-wrap {
      margin-top: 48px;

      .wrap-item {
        flex: 1;
        padding: 20px;
        border: 1px solid rgba(255, 255, 255, 0.20);

        &:nth-child(2) {
          margin-left: 20px;
        }

        .img1 {
          flex: 0 0 72px;
          width: 72px;
          height: 72px;
          margin-right: 24px;
        }

        .item-info {
          flex: 1;
          font-size: 20px;
          line-height: 24px;

          .info-name {

            color: #C9FA5B;

            img {
              width: 24px;
              height: 24px;
              margin-left: 12px;
            }
          }

          .info-desc {

            margin-top: 16px;
            color: rgba(255, 255, 255, 0.8);
          }
        }

        .img2 {
          flex: 0 0 24px;
          width: 24px;
          height: 24px;
          margin: 24px 0 0 24px;
          cursor: pointer;
        }
      }
    }

    .market-reviset {
      margin-top: 12px;

      .reviset-notice {
        flex: 1;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 36px;

        span {
          color: #C9FA5B;
        }

        img {
          flex: 0 0 20px;
          width: 20px;
          height: 20px;
          margin: 8px 10px 0 0;
        }

        p {
          display: none;
        }
      }

      .reviset-btn {
        .btninfo {
          max-width: 180px;
          height: 36px;
          margin-right: 14px;
          background: #181818;
          border-radius: 20px;
          cursor: pointer;
          padding: 0 20px;
          font-size: 13px;
          color: #FFFFFF;

          &:last-child {
            margin-right: 0;
          }

          img {
            width: 20px;
            height: 20px;
            margin: 0 7px 0 0;
          }
        }
      }
    }

    &-out {
      width: 100%;
      background: url("../../public/images/new/nodebg.png") top no-repeat;
      background-size: 100% auto;
    }

    .node-type {
      padding: 24px 0 0;

      .type-name {
        font-size: 20px;
        color: #fff;

        p {
          display: none;
        }
      }

      .type-list {
        position: relative;
        margin: 20px 0 40px;
        flex-wrap: wrap;

        .lefticon1 {
          position: absolute;
          top: 50%;
          left: -44px;
          width: 20px;
          height: 32px;
          transform: translateY(-50%);
          cursor: pointer;
        }

        .lefticon2 {
          position: absolute;
          top: 50%;
          right: -44px;
          width: 20px;
          height: 32px;
          transform: translateY(-50%);
          cursor: pointer;
        }

        .list-info {
          position: relative;
          flex: 0 0 404px;
          height: 185px;
          margin-right: 14px;
          background: #121212;
          border-radius: 16px;
          cursor: pointer;
          border: 2px solid #121212;
          box-sizing: border-box;
          transition: 0.5s;
          margin-top: 14px;

          &:nth-child(-n + 3) {
            margin-top: 0;
          }

          &:hover {
            position: relative;
            border: 2px solid #d2fb71;
            transition: 0.5s;

            &::after {
              content: "";
              position: absolute;
              top: 8px;
              left: 8px;
              width: 388px;
              height: 164px;
              background: url("../../public/images/new/tagicon.png") center no-repeat;
              background-size: 100% 100%;
              transition: 0.5s;
              z-index: 0;
            }
          }

          &:nth-child(3n) {
            margin-right: 0;
          }

          .list-con {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 24px 0;
            z-index: 5;
          }

          .info-img {
            flex: 0 0 132px;
            width: 132px;
            height: 132px;
            margin: 0 20px;
            border-radius: 50%;
          }

          .info-desc {
            flex: 1;
            margin-right: 20px;

            .desc-name {
              margin-bottom: 16px;
              font-size: 20px;
              color: #fff;
              line-height: 28px;
            }

            .desc-price {
              flex-wrap: wrap;

              .price {
                flex: 0 0 50%;
                margin-bottom: 12px;

                &:last-child {
                  flex: 0 0 100%;
                  margin-bottom: 0;
                }

                div {
                  margin-bottom: 8px;
                  font-size: 16px;
                  color: #ffffff;
                  line-height: 18px;
                }

                p {
                  display: flex;
                  font-size: 12px;
                  color: #a8a8a8;
                  line-height: 12px;

                  img {
                    width: 12px;
                    height: 12px;
                    margin-left: 4px;
                  }
                }
              }
            }
          }
        }
      }

      .h5type {
        display: none;
      }

      .type-select {
        display: flex;

        .select {
          flex: 0 0 160px;
          margin-right: 24px;

          &:last-child {
            flex: 0 0 240px;
            margin-right: 0;

            p {
              width: 100%;
              border: 0;
            }

            input {
              width: 49%;
              padding: 0 14px;
              height: 40px;
              font-size: 16px;
              color: #fff;
              border: 1px solid rgba(255, 255, 255, 0.2);
              box-sizing: border-box;

              &::placeholder {
                color: rgba(255, 255, 255, 0.4);
              }

              &:last-child {
                margin-left: 2%;
              }
            }
          }

          div {
            margin-bottom: 10px;
            font-size: 13px;
            color: #a8a8a8;
          }

          p {
            width: 100%;
            height: 40px;
            border: 1px solid rgba(255, 255, 255, 0.2);

            ::v-deep .el-select {
              width: 100%;
              height: 38px;
              color: #fff;

              .el-input {
                height: 38px;

                .el-input__wrapper {
                  background-color: rgba(255, 255, 255, 0);
                  box-shadow: none !important;
                }

                .el-input__inner {
                  color: #fff;
                }
              }
            }
          }
        }
      }

      .node-planet {
        padding: 20px 0 40px;
        flex-wrap: wrap;

        .planet-list {
          flex: 0 0 236px;
          width: 236px;
          height: 370px;
          position: relative;
          margin: 14px 14px 0 0;
          background: #181818;
          border-radius: 16px;
          border: 2px solid #181818;
          box-sizing: border-box;
          cursor: pointer;
          overflow: hidden;
          transition: 0.5s;

          &:hover {
            border: 2px solid #d2fb71;
            transition: 0.5s;

            .icon {
              display: block;
            }

            .planet-info {
              .planet-type {
                .price {
                  &:nth-last-child(-n + 2) {
                    display: none;
                    transition: 0.5s;
                  }
                }
              }

              .planet-btn {
                display: block;
              }
            }
          }

          &:nth-child(5n) {
            margin-right: 0;
          }

          &:nth-child(-n + 5) {
            margin-top: 0;
          }

          .icon {
            position: absolute;
            top: 8px;
            left: 8px;
            width: 220px;
            display: none;
          }

          .img {
            width: 196px;
            height: 196px;
            margin: 20px 20px 8px;
            border-radius: 50%;
            object-fit: cover;
            transition: 0.5s;
          }

          .planet-info {
            padding: 0 20px 24px;

            .planet-name {
              font-size: 18px;
              color: #fff;
              line-height: 18px;
            }

            .planet-type {
              margin-top: 16px;
              flex-wrap: wrap;

              .price {
                flex: 0 0 50%;
                margin-top: 12px;

                &:nth-child(-n + 2) {
                  margin-top: 0;
                }

                div {
                  font-size: 16px;
                  color: #fff;
                  line-height: 18px;
                }

                p {
                  margin-top: 8px;
                  font-size: 12px;
                  color: #a8a8a8;
                  line-height: 12px;
                }
              }
            }

            .planet-btn {
              margin-top: 20px;
              width: 100%;
              height: 44px;
              background: url("../../public/images/new/btnbg.png") center no-repeat;
              background-size: 100% 100%;
              text-align: center;
              font-size: 14px;
              color: #000000;
              line-height: 44px;
              display: none;
            }
          }
        }
      }
    }
  }

  .planet-page {
    padding: 0;

    ::v-deep .el-pagination {
      button {
        background-color: rgba(255, 255, 255, 0);
        color: #666666;
        font-size: 14px;
      }

      .el-pager {
        li {
          background: rgba(255, 255, 255, 0);
          font-size: 14px;
          color: #666666;
        }

        .is-active {
          background: #c9fa5b !important;
          border-radius: 4px !important;
          color: #000000 !important;
        }
      }
    }
  }
}

.hideCon {
  height: 100vh;
  overflow: hidden;
}

.modal-con {
  position: relative;
  padding: 32px;
  width: 480px;

  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal-title {
    margin-bottom: 32px;
    font-size: 20px;
    color: #ffffff;
  }

  .modal-desc {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  .modal-btn {
    margin-top: 32px;
    justify-content: space-between;

    div {
      flex: 0 0 197px;
      height: 50px;
      background: url("../../public/images/new/btnbg14.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;

      &:last-child {
        background: url("../../public/images/new/btnbg15.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }
}

#footer {
  display: none;
}

::v-deep .van-popup {
  border-radius: 20px;
  overflow-y: initial;
}

.notice-con {
  position: relative;
  width: 500px;
  padding: 40px 0;
  max-height: 653px;
  background: url("../../public/images/reward/rulesbg.png") top no-repeat;
  background-size: 100% 653px;

  &::after {
    content: '';
    position: absolute;
    top: -30px;
    right: 45px;
    width: 154px;
    height: 158px;
    background: url("../../public/images/reward/rules.png") top no-repeat;
    background-size: 100% 100%;
  }

  .close {
    position: absolute;
    bottom: -58px;
    left: 50%;
    width: 38px;
    height: 38px;
    transform: translateX(-50%);
    cursor: pointer;
  }

  .notice-name {
    padding: 20px 40px 0;
    margin-bottom: 32px;
    font-size: 24px;
    color: #c9fa5b;
    line-height: 24px;
  }

  .notice-title {
    padding: 0 40px 12px;
    font-size: 18px;
    color: #ffffff;
  }


  .rules-list {
    max-height: 330px;
    overflow-y: scroll;
    padding: 0 40px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);

    div {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        color: #C9FA5B;
      }
    }

    &::-webkit-scrollbar {
      width: 0px; //修改滚动条宽度
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #666666;
    }
  }

  .notice-time {
    padding: 23px 40px 0;
    font-size: 16px;
    text-align: right;
    color: rgba(255, 255, 255, 0.6);
  }

  .notice-btn {
    margin: 23px auto 0;
    width: 418px;
    height: 52px;
    background: url("../../public/images/new/btnbg25.png") center no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    font-size: 18px;
    color: #000000;
    text-align: center;
    line-height: 52px;
  }
}

@media (max-width: 1200px) {
  .node {
    padding: 68px 0 80px;

    .node-con {
      width: calc(100vw - 8px);

      .activity-wrap {
        margin: 12px 0;
        width: 100%;
        justify-content: left;

        .wrap-item {
          flex: 1;
          padding: 12px;

          &:nth-child(2) {
            margin-left: 24px;
          }

          .img1 {
            flex: 0 0 48px;
            width: 48px;
            height: 48px;
            margin-right: 12px;
          }

          .item-info {
            font-size: 14px;
            line-height: normal;

            .info-name {
              img {
                width: 14px;
                height: 14px;
              }
            }

            .info-desc {
              margin-top: 4px;
            }
          }

          .img2 {
            margin: 12px 0 0 12px;
          }
        }
      }

      .market-reviset {
        margin-top: 12px;

        .reviset-notice {
          flex: 1;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 36px;

        }

        .reviset-btn {
          .btninfo {
            padding: 0;
            width: 36px;

            div {
              display: none;
            }

            img {
              margin: 0;
            }
          }
        }
      }

      .node-type {
        margin-bottom: 0;
        padding-bottom: 40px;

        .type-list {

          .lefticon1,
          .lefticon2 {
            display: none;
          }

          .list-info {
            margin-top: 0;
            margin-right: 0;

            &:hover {
              position: relative;
              border: 0 solid #d2fb71;

              &::after {
                background: none !important;
              }
            }
          }
        }

        .pctype {
          display: none;
        }

        .h5type {
          display: block;

          .line-all {
            position: absolute;
            bottom: -12px;
            left: 50%;
            transform: translateX(-50%);

            div {
              width: 6px;
              height: 4px;
              border-radius: 2px;
              background: rgba(255, 255, 255, 0.2);
              margin-right: 4px;

              &:last-child {
                margin-right: 0;
              }
            }

            .subact {
              width: 12px;
              background: #fff;
            }
          }
        }

        .type-select {
          flex-wrap: wrap;

          .select {
            flex: 0 0 23%;
            margin-right: 2%;

            &:last-child {
              flex: 0 0 25%;
            }
          }
        }

        .node-planet {
          .planet-list {
            flex: 0 0 49%;
            display: flex;
            padding: 16px 16px 16px 0;
            height: fit-content;
            margin: 0 2% 2% 0;

            &:hover {
              .icon {
                display: none;
              }

              .img {
                margin-top: 0;
              }
            }

            &:nth-child(5n) {
              margin-right: 2%;
            }

            &:nth-child(2n) {
              margin-right: 0;
            }

            .img {
              flex: 0 0 112px;
              width: 112px;
              height: 112px;
              margin: 16px 0 0 0;
            }

            .planet-info {
              padding: 0;
              flex: 1;

              .planet-type {
                .price {
                  &:nth-last-child(-n + 2) {
                    display: block !important;
                  }
                }
              }

              .planet-btn {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #footer {
    display: block;
  }

  .modal-con {
    padding: 14px;
    width: calc(100vw - 8px);



    .close {
      top: 14px;
    }

    .modal-btn {
      div {
        flex: 0 0 48%;
      }
    }
  }

  .node {
    padding: 56px 0 100px;
    background: #000;

    .node-title {
      ::v-deep .el-carousel__container {
        height: 152px !important;
      }

      .bannerimg {
        width: 100%;
        height: 152px;
        object-fit: cover;
      }
    }

    .node-con {
      .activity-wrap {
      overflow-y: scroll;

      .wrap-item {
        flex: 0 0 304px;
      }
    }
      .market-reviset {
        margin-top: 12px;
        flex-wrap: wrap;

        .reviset-notice {
          flex: 1;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 32px;
          height: 32px;

          img {
            margin-top: 6px;
          }

          div {
            flex: 1;
            height: 32px;
            // line-height: normal !important;
          }

          p {
            display: flex;
            color: #C9FA5B;

            img {
              flex: 0 0 6px;
              width: 6px;
              height: 10px;
              margin: 12px 0 0 4px;
            }
          }
        }

        .reviset-btn {
          margin-top: 6px;
          justify-content: flex-end;

          .btninfo {
            padding: 0;
            width: 20px;
            height: 20px;
            margin-right: 6px;

            div {
              display: none;
            }

            img {
              margin: 0;
            }
          }
        }
      }

      .node-type {
        .type-name {
          position: relative;
          font-size: 16px;

          p {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            font-size: 16px;
            color: #c9fa5b;
            line-height: 16px;

            img {
              width: 5px;
              height: 9px;
              margin-top: 4px;
              margin-left: 4px;
            }
          }
        }

        .type-list {
          .list-info {
            flex: 0 0 303px;
            padding: 16px 0;

            .info-img {
              flex: 0 0 80px;
              width: 80px;
              height: 80px;
              margin: 0 12px 0 16px;
            }

            .info-desc {
              margin-right: 16px;

              .desc-name {
                font-size: 16px;
              }

              .desc-price {
                .price {
                  flex: 0 0 50%;
                }
              }
            }
          }
        }

        .type-select {
          flex-wrap: wrap;

          .select {
            flex: 0 0 49%;
            margin-right: 2%;

            &:nth-child(2n) {
              margin-right: 0;
            }

            &:last-child {
              flex: 0 0 49%;
            }

            &:nth-child(-n + 2) {
              margin-bottom: 2%;
            }
          }
        }

        .node-planet {
          .planet-list {
            flex: 0 0 100%;
            margin-right: 0 !important;

            &:hover {
              border: 2px solid #181818;

              .img {
                margin-top: 20px;
              }
            }

            .planet-info {
              .planet-name {
                font-size: 16px;
              }

              .planet-type {
                .price {
                  flex: 0 0 50%;
                  font-size: 12px;

                  &:nth-child(3) {
                    display: none;
                  }

                  div {
                    font-size: 12px;
                  }
                }
              }

              .planet-btn {
                width: 115px;
                height: 32px;
                line-height: 32px;
              }
            }
          }
        }
      }
    }
  }

  .notice-con {
    width: 298px;
    padding: 20px 0;

    &::after {
      display: none;
    }

    .close {
      bottom: -40px;
      width: 28px;
      height: 28px;
    }

    .notice-name {
      padding: 0 16px;
      margin-bottom: 12px;
      font-size: 16px;
    }

    .notice-title {
      padding: 0 16px 8px;
      font-size: 14px;
    }

    .rules-list {
      padding: 0 16px;
      font-size: 14px;

      div {
        margin-bottom: 8px;
      }
    }

    .notice-time {
      padding: 23px 16px 0;
      font-size: 14px;
    }

    .notice-btn {
      width: 262px;
      height: 40px;
      margin: 12px auto 0;
      background: url("../../public/images/new/btnbg26.png") top no-repeat;
      background-size: 100% 100%;
      font-size: 14px;
      line-height: 38px;
    }
  }
}</style>
